$(document).on('turbolinks:load', function(){
  window.activateCarousel = function(){
     $('.carousel-elem').on('mouseover click', function(){
      $(".desc").addClass('hidden')
      $("#desc-" + $(this).data('id')).removeClass('hidden')
    });

    $('.media-carousel').on('mousewheel DOMMouseScroll', function(e){
      var delta = Math.max(-1, Math.min(1, (e.originalEvent.wheelDelta || -e.originalEvent.detail)));
      $(this).scrollLeft( $(this).scrollLeft() - ( delta * 150 ) );
      e.preventDefault();
    });
    $('.media-carousel-left').on('click', function(){
      $('.media-carousel').animate({scrollLeft: $('.media-carousel').scrollLeft() - 223},800);
    });
    $('.media-carousel-right').on('click', function(){
      $('.media-carousel').animate({scrollLeft: $('.media-carousel').scrollLeft() + 223},800);
    });
  }
  activateCarousel();
});

