$(document).on('turbolinks:load', function(){
	$('.donation-option-button').on('click', function(e){
		let amounts = [];
		$('.amount', '.options').each(function(index, value){
			if(value.textContent !== '?') amounts.push(value.textContent.replace(/'/g, ''))
		})
		let amount = $('.amount', $(this).parent().parent()).text()
		$('.options').hide();
		$('#back-button').show();
		let selectedAmount = amount !== '?' ? amount.replace(/'/g, '') : 0 
		let autoselect = amount !== '?' 
		window.rnw.tamaro.runWidget('.rnw-widget-container', {language: "de", amounts: amounts, defaultAmount: parseInt(selectedAmount), autoselectAmount: autoselect})
	});

	$('#back-button').on('click', function(e){
		$('.rnw-widget-container').children().hide();
		$('.options').show();
		$('#back-button').hide();
	});
});