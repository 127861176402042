// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
jQueryBridget( 'masonry', Masonry, $ );
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );
require('search')
require('media_carousel')
require('donations')

$(document).on('turbolinks:load', function(){

  $('.grid').imagesLoaded(function(){
    $('.grid').masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.grid-item',
      // use element for option
      columnWidth: 154,
      gutter: 20,
      //percentPosition: true
    })
  })


  // Jump to correct position with the navigation
  window.jumpToSection = function(elem){
    var selected_elem = $('#' + elem.data('elem'));

    var navItems = $('.nav-link');
    navItems.each(function(idx, li) {
      $(li).removeClass('black');
    });
    elem.addClass('black');

    if(selected_elem.length){
      var element_pos   = selected_elem.offset().top
      var container_pos = $('.container-w.active .container').offset().top;
      $('.container-w.active').animate({scrollTop: element_pos - container_pos }, 700)
    }
  }

  // Imitate a callback for html() function. Ensure that content is loaded on click
  window.remoteJumpToSection = function(last_elem){
    var timer = setInterval(function(){
       if (last_elem.length) {
           jumpToSection($('.nav-link.black'));
           clearInterval(timer);
       }
    }, 500);
  }

  // Activate naviation
  function activateNavigation(elem){
    $('#supportpage-nav-w').removeClass('active');
    $("#body ul li, #body ul .nav-w").each(function(idx, li) {
        $(li).removeClass('active');
    });
    elem.addClass('active');

    // Make the correct containers active
    var active_container = elem.next('.container-w');
    // Dont make the main container active when clicking on support page
    if (!(active_container.attr('id') == 'main_container')){
      active_container.addClass('active');
    }
  }

  // Remove logo preview after certain time
  setTimeout(function(){
    $('#preview').remove();
  }, 1300);

  // Use regular menu to navigate
  $('.nav a').on('click', function(e){
    e.preventDefault();
    activateNavigation($(this).closest('.nav-w'))
    jumpToSection($(this));
  });

  // Use header links to navigate
  $('.mobile-header-elem .nav-link').on('click', function(e){
    e.preventDefault();
    activateNavigation($('#' + $(this).data('parent') + '-nav-w'));
    jumpToSection($(this));
    $('#show_search').prop('checked', false);
  })

  // Close header and footer on click
  $("#footer-show").on('click', function(){$('#show_search').prop('checked', false);});
  $("#show_search").on('click', function(){$('#show_footer').prop('checked', false);});
});
