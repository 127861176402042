$(document).on('turbolinks:load', function(){
  $('#magnifier').on('click', () => {
    $('#search-input').focus();
  })
  $('#search-input').on('input', function(e){
    e.preventDefault()
    $.ajax({
      type: 'POST',
      url: '/search',
      data: {q: $(this).val()},
      success: function(data){
        var results = ''
        $(JSON.parse(data)).each(function(idx, elem) {
          results += "<a href='"+elem['slug']+"'><h3>"+ (!elem['name'] ? elem['slug'] : elem['name']) +"</h3><p>..."+elem['text']+"...</p></a><br>";
        });
        $('#results').html(results);
      },
      error: function(data){

      }
    });
  });
});